
import { Component, Vue } from 'vue-property-decorator';
import SectionCard from '@/components/SectionCard.vue';
import RouteTitle from '@/components/RouteTitle.vue';
import UIUtil from '@/utils/ui.util';
import RouterUtil from '@/utils/router.util';
import { RouteConfig } from 'vue-router';

@Component({
  components: {
    RouteTitle,
    SectionCard,
  },
})
export default class SectionChildrenCards extends Vue {
  sections: RouteConfig[] = [];

  generateSrc(fileName: string): string {
    return UIUtil.generateImagePathbyFileName(fileName);
  }

  getPath(child: RouteConfig): string {    
    return `${this.$route.path}/${child.path}`;
  }

  sortRoutes(a: RouteConfig, b:RouteConfig): number {
    if (a.meta && a.meta.userAllowed && (!b.meta || !b.meta.userAllowed)) return -1;
    if (b.meta && b.meta.userAllowed && (!a.meta || !a.meta.userAllowed)) return 1;
    return 0;
  }

  getCards(): void {
    this.sections = RouterUtil.getChildrenByParentPath(this.$route.path, this.$store.state.user.userRoutes);
    this.sections.sort(this.sortRoutes);
  }

  mounted(): void {
    this.getCards();
  }
}
